<template>
  <div>
    <section id="about" class="section-content">
      <div class="container">
        <h2>Privacidade, segurança e modernidade</h2>
        <div class="row">
          <div class="col-lg-5 section-content-illustration">
            <figure class="figure">
              <img
                src="/images/img-about-aplicacao-pelicula-2x.jpg"
                alt="Aplicação de Película nas janelas do Aeroporto de Guarulhos"
                class="figure-img img-fluid rounded"
                loading="lazy"
              />
            </figure>
          </div>
          <!-- col end -->
          <div class="col-lg-7">
            <p>A MF Film tem mais de 15 anos de experiência com profissionais especializados e dedicados na aplicação de Películas e Adesivos.</p>

            <p>Trabalhamos com arquitetos, decoradores, engenheiros civis, vidraçarias, área automotiva ou direto com o proprietário do ambiente como casas, apartamentos, sacadas, condomínios, empresas, lojas.</p>

            <p>A MF usa materiais de qualidade com garantia de fábrica e excelente estrutura para melhor atender os padrões de qualidades exigidos no mercado.</p>

            <p>
              Confira
              <router-link :to="{ name : 'work'}">Nossos Trabalhos</router-link>&nbsp;e fique a vontade para pedir uma
              <router-link :to="{ name : 'quotation' }">Cotação</router-link>.
            </p>
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>

    <QuotationCall />
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";

//Lazy Loading
const QuotationCall = () => import(/* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue");

export default {
  components: {
    QuotationCall,
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Quem Somos");
    this.$emit(
      `update:introduction`,
      "Nossos profissionais possuem mais de 15 anos de experiência na instalação de Películas e Adesivos"
    );
    this.$emit(`update:bgHeader`, "green");
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";


@media (min-width: $screenLarge) {
  .section-content-illustration {
    order: 2;
  }
}
</style>